@font-face {
  font-family: 'CodecPro';
  src:
    local('CodecPro'),
    url(./resources/fonts/CodecPro-Regular.ttf) format('truetype');
}

a:visited, a:link, a:active {
  text-decoration: none !important;
  color: gray
}

a:hover {
  color: #b8de6f !important
}

.App {
  background-color: #282c34;
  text-align: center;
  min-height: 100vh;
  font-family: 'CodecPro', sans-serif;
  font-size: 0.8em;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.app-container {
  margin-top: 0 !important;
}

body {
  background-color: #282c34;
}

.consent-container {
  text-align: left;
}

.footer {
  background-color: #282c34;
}

.footer a:focus {
  outline: 0 !important
}

.github {
  width: 100%;
  text-align: center;
}

.github-calendar {
  margin: auto;
  display: inline-block;
  max-width: 70vw;
  
}

.header {
  width: 100%;
  text-align: center;
}

.react-activity-calendar__footer {
  margin: auto;
  display: inline-block !important;
}

.social-icon {
  vertical-align: middle;
}

.title-img, .home-img {
  max-width: 55vw;
  max-height: 25vh;
  margin: 5%;
}

#linkedin-icon {
  background-color: white;
}

#rcc-confirm-button {
  font-family: 'CodecPro', sans-serif;
  text-align: center;
}

text {
  fill: rgb(256, 256, 256) !important;
}